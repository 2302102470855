<template>
  <div class="view" @click="onPoi(poi)">
    <div class="image-container">
      <img class="poi-image" :src="getThumb()" />
      <img class="poi-play-button" src="/assets/svgs/play_button.svg" />
    </div>
    <p>{{ asset.name }}</p>
  </div>
</template>

<script>
export default {
  name: "PoiItem",
  props: ["poi", "asset"],
  data() {
    return {
      exhibition: this.$root.$data.exhibition,
    };
  },
  methods: {
    onPoi(poi) {
      this.$emit("onPoiItem", poi);
    },
    getThumb() {
      if (this.asset.video === undefined) {
        return "/assets/images/" + this.asset.image;
      }
      return "/assets/images/" + this.asset.video + ".jpg";
    },
  },
};
</script>

<style scoped>
.view {
  cursor: pointer;
  max-width: calc(3.6 * var(--floor-frac));
}

.image-container {
  position: relative;
  width: calc(2 * var(--floor-frac));
  height: calc(1.125 * var(--floor-frac));
  margin: 0 auto;
}

.poi-image {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  border-radius: calc(0.1 * var(--floor-frac));
  box-shadow: 0 1px 2px 0 var(--color-medium-alpha),
    0 2px 5px 0 var(--color-medium-light-alpha);
  opacity: 1;
}

.poi-play-button {
  position: absolute;
  width: 8px;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.poi-image:hover {
  opacity: 0.8;
}

p {
  background-color: var(--color-primary-alpha);
  font-size: calc(0.4 * var(--floor-frac));
  text-align: center;
  width: 100%;
  overflow: visible;
  margin: 0 auto;
}

@media screen and (max-device-width: 660px) {
  .view {
    cursor: pointer;
    max-width: calc(3.6 * var(--floor-frac));
  }

  .image-container {
    position: relative;
    width: calc(2.88 * var(--floor-frac));
    height: calc(1.62 * var(--floor-frac));
    margin: 0 auto;
  }
  .poi-play-button {
    position: absolute;
    width: 3px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    background-color: var(--color-primary-alpha);
    font-size: calc(0.6 * var(--floor-frac));
    text-align: center;
    width: 100%;
    overflow: visible;
    margin: 0 auto;
  }
}
</style>
